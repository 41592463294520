import React from "react";
import logo from "./logo.svg";
import "./App.css";

function App(): JSX.Element {
  return (
    <>
      <div className="App">
        <header className="App-header">
          Next big stuff
          <h2>G-DATA</h2>
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Copyright © {new Date().getFullYear()}{" "}
            <a
              className="App-link"
              href="https://aterzini.com"
              target="_blank"
              rel="noreferrer"
            >
              ATERZINI TECH{" "}
            </a>
            | All Rights Reserved.
          </p>
        </header>
      </div>
    </>
  );
}

export default App;
